@import 'styles/mixins/media-queries';
@import 'styles/mixins/pod-3-fonts';

.hero {
	overflow: hidden;
	position: relative;

	:global(.background-img) img {
		max-height: calc(100dvh - 112px);
	}

	:global(.content) {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 120px 0;

		@include sm-down {
			align-items: flex-start;
		}
	}
}

.disclaimer {
	max-width: 350px;
	margin: auto;
	font-size: 12px;
	text-align: center;
}

.logo {
	width: clamp(15rem, 60vw, 22rem);
	height: 5rem;

	display: block;
}

.raffle_section {
	background-color: white;
	color: black;
}

.raffle_text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-bottom: 60px;
}

.raffle_header {
	@include pod-3-header-3;
	margin: auto;

	@include sm-down {
		@include pod-3-header-4;
		margin: auto;
	}
}

.giveaway_container {
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	min-height: 50px;
}

.mid_section_h2 {
	@include pod-3-header-2;
	margin: 0 0 45px 0;
	max-width: 17ch;

	@include sm-down {
		@include pod-3-header-3;
		margin: 0 0 30px 0;
		max-width: 17ch;
	}
}

.mid_section {
	max-width: 1440px;
	margin: 0 auto;
	color: black;
	display: flex;
	align-items: center;
	column-gap: 30px;
	row-gap: 30px;

	> div {
		flex: 1 0 0;
	}

	.mid_section_p {
		max-width: 374px;
	}

	@include sm-down {
		flex-direction: column;

		.mid_section_content {
			padding: 0 30px;
		}
	}
}

div.shop_card {
	padding-left: 0;
	padding-right: 0;
}

.images_section {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 30px;

	@include xs-only {
		display: none;
	}
}

.anchor_link_wrapper {
	width: 3rem;
	height: 3rem;

	img {
		width: 100%;
		height: 100%;
	}

	z-index: 300;
	position: absolute;

	left: 50%;
	transform: translateX(-50%);

	bottom: 4rem;

	@include md-up {
		display: none;
	}
}
