@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.hero {
	overflow: hidden;
	position: relative;
	height: 100dvh;

	> div,
	img {
		height: 100%;
	}

	:global(.content) {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 120px 0;

		.logo {
			width: 90vw;
			max-width: 875px;
			height: 5rem;

			display: block;
		}
	}
}

.raffle_section {
	background: #f2f2f2;
	color: black;
}

.raffle_text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-bottom: 60px;

	> p {
		@include body3;
		margin-top: 30px;
		max-width: 570px;
		margin-inline: auto;
	}
}

.raffle_header {
	@include headline3;
	margin: auto;
	max-width: 22ch;
	line-height: 120%;

	@include sm-down {
		@include headline4;
		margin: auto;
		line-height: 120%;
	}
}

.giveaway_container {
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	min-height: 50px;
}

.h2 {
	@include headline3;
	text-align: center;
}

.images_section {
	display: flex;
	flex-direction: column;
	row-gap: 100px;

	@include sm-down {
		row-gap: 80px;
	}

	.row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 100px;
		align-items: center;

		@include sm-down {
			grid-template-columns: 1fr;
			row-gap: 20px;
		}

		.image_text {
			max-width: 460px;
		}

		img {
			border-radius: 16px;
			border: 1px solid rgba(238 238 238 / 20%);
		}

		@include md-up {
			&:nth-child(even) {
				.image_text {
					order: 1;
				}

				img {
					order: 2;
				}
			}
		}

		small {
			font-size: 14px;
			color: #a6a6a6;
			text-transform: uppercase;
			letter-spacing: 2.03px;
		}

		h3 {
			@include headline5;
			margin-top: 20px;
			margin-bottom: 24px;
		}

		p {
			margin: 0;
		}
	}
}

.anchor_link_wrapper {
	width: 3rem;
	height: 3rem;

	img {
		width: 100%;
		height: 100%;
	}

	z-index: 300;
	position: absolute;

	left: 50%;
	transform: translateX(-50%);

	bottom: 4rem;

	@include md-up {
		display: none;
	}
}

.disclaimer {
	max-width: 350px;
	margin: auto;
	font-size: 12px;
	text-align: center;
}

div.shop_card {
	padding-left: 0;
	padding-right: 0;
	background-color: white;

	@include sm-down {
		background-color: black;
	}
}

.shop_card_container {
	background-color: white;
	color: black;

	@include sm-down {
		background-color: black;
		color: white;
	}
}
