@import 'styles/mixins/media-queries';
@import 'styles/mixins/pod-3-fonts';
@import 'styles/mixins/fonts-2023';

.hero {
	overflow: hidden;
	position: relative;
	height: 100dvh;

	> div,
	img {
		height: 100%;
	}

	:global(.content) {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 120px 0;

		@include sm-down {
			align-items: flex-start;
		}
	}
}

.logo {
	width: clamp(15rem, 60vw, 22rem);
	height: 5rem;

	display: block;
}

.raffle_section {
	background-color: white;
	color: black;
}

.raffle_text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-bottom: 60px;

	> p {
		margin-top: 30px;
	}
}

.raffle_header {
	@include pod-3-header-3;
	margin: auto;
	max-width: 33ch;
	line-height: 120%;

	@include sm-down {
		@include pod-3-header-4;
		margin: auto;
		line-height: 120%;
	}
}

.giveaway_container {
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	min-height: 50px;
}

.images_section {
	display: grid;
	row-gap: 60px;

	.quote,
	.quote2 {
		font-size: 32px;
		display: grid;
		grid-template-columns: 1fr 1.5fr;
		color: black;
		grid-gap: 60px;
		align-items: center;

		@include sm-down {
			display: flex;
			flex-direction: column-reverse;
			font-size: 22px;
			grid-gap: 30px;
		}

		h3 {
			font-size: 1em;
			font-weight: 700;
			line-height: 120%;
		}

		p {
			margin: 0;
			font-size: 0.6em;
		}
	}

	.quote2 {
		grid-template-columns: 1.5fr 1fr;

		@include sm-down {
			display: flex;
			flex-direction: column;
		}
	}
}

.anchor_link_wrapper {
	width: 3rem;
	height: 3rem;

	img {
		width: 100%;
		height: 100%;
	}

	z-index: 300;
	position: absolute;

	left: 50%;
	transform: translateX(-50%);

	bottom: 4rem;

	@include md-up {
		display: none;
	}
}

.mid_section {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	column-gap: 120px;
	max-width: 80rem;
	margin: auto;
	color: black;

	.mid_section_h2 {
		@include heading4;
		margin-bottom: 2rem;
	}

	.mid_section_p {
		@include body5;
	}

	@include sm-down {
		grid-template-columns: 1fr;
		row-gap: 0;

		img {
			display: none;
		}

		.mid_section_h2 {
			@include heading5;
			margin-bottom: 2rem;
		}
	}
}

.disclaimer {
	max-width: 350px;
	margin: auto;
	font-size: 12px;
	text-align: center;
}

div.shop_card {
	padding-left: 0;
	padding-right: 0;
}
