@import 'styles/constants';
@import 'styles/mixins/media-queries';

.img {
	width: 100%;
	max-height: 100%;
	position: relative;
	display: block;
	object-fit: contain;
}

.img_background {
	width: 100%;
	position: relative;

	> :global(.absolute-cover) {
		z-index: 1;
	}

	:global(.background-img) {
		display: flex;
		width: 100%;
		height: 100%;

		> img {
			object-fit: cover;
			object-position: center center;
		}
	}

	.content {
		position: relative;
		z-index: 2;
	}
}

.background_image {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;

	@include xs-only {
		min-height: 50vh;
		display: flex;
	}
}
