@import 'styles/mixins/pod-3-fonts';
@import 'styles/mixins/media-queries';

.container {
	background: none;
	color: black;
	margin: auto;
	padding: 0 74px;

	@include sm-down {
		padding: 0 150px;
	}

	@include xs-only {
		padding: 0 22.5px;
	}

	overflow: hidden;
}

.inner_wrapper {
	max-width: 1068px;
	position: relative;
	margin: auto;

	@include sm-down {
		max-width: 469px;
	}
}

.opacity {
	opacity: 1 !important;

	> * {
		opacity: 1 !important;
	}
}

.top_section {
	position: relative;
	z-index: 1;
	width: 100%;
	display: flex;
	align-items: flex-end;
	opacity: 1;

	@include sm-down {
		flex-direction: column-reverse;
		align-items: flex-start;
	}
}

.top_section_image {
	width: 40%;
	display: block;

	@include sm-down {
		width: 50%;
	}

	@include xs-only {
		width: 73%;
	}
}

.heading {
	@include pod-3-header-3;
	max-width: 350px;
	margin: 53px 53px 60px;

	@include sm-down {
		@include pod-3-header-4;
		margin: 0;
		max-width: 330px;
		padding-bottom: 30px;
	}
}

.bottom_section {
	position: relative;
	z-index: 2;

	margin-top: -30px;
	width: 100%;
	display: flex;
	flex-direction: row-reverse;
	align-items: flex-end;

	@include sm-down {
		flex-direction: column;
	}

	@include xs-only {
		margin-top: -60px;
	}
}

.bottom_section_image {
	width: 65%;
	display: block;

	@include sm-down {
		width: 86%;
	}

	@include xs-only {
		width: 90%;
	}
}

.description {
	flex: 1;
	@include pod-3-body-2;
	margin-right: 35px;

	@include sm-down {
		@include pod-3-body-4;
		margin: 0;
		padding-top: 30px;
	}
}
