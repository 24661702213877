.content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video {
	position: relative;

	video {
		width: 100%;
		display: block;
	}

	:global(.video-react) {
		background-color: #fff;
		padding-top: 0px !important;
	}

	:global(.video-react-big-play-button) {
		display: none;
	}

	button:global(.video-react-big-play-button) {
		display: none;
	}

	:global(.video-react-video):focus {
		outline: none;
	}
}
