@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.root {
	background-color: white;
	color: black;
}

.container {
	max-width: 1440px;
	margin: auto;
	min-height: 100vh;

	> .heading {
		@include headline4;
		text-align: center;
		margin-bottom: 24px;

		@include xs-only {
			font-size: 32px;
			margin-bottom: 24px;
		}
	}

	> p {
		@include body1;
		text-align: center;
		margin-bottom: 40px;

		@include xs-only {
			font-size: 18px;
			margin-bottom: 40px;
		}
	}
}

.grid {
	--grid-item-max-width: 220px;

	display: grid;
	grid-auto-rows: 1fr;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 30px;
	row-gap: 30px;

	@include sm-only {
		grid-template-columns: repeat(2, 1fr);
	}

	@include xs-only {
		grid-template-columns: 1fr;
		row-gap: 15px;
	}

	.background_image {
		border-radius: 20px;
		overflow: hidden;

		@include xs-only {
			border-radius: 10px;
		}

		img {
			object-position: top left;
			aspect-ratio: 724 / 1039;
		}

		> :global(.content) {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			color: #fff;
			padding: 20px;
			font-size: 16px;
			line-height: 1.6;

			@include xs-only {
				font-size: 12px;
			}
		}
	}

	&.gradient {
		position: relative;

		@include md-up {
			&::before {
				content: '';
				position: absolute;
				inset: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
				background: linear-gradient(180deg, rgba(255 255 255 / 0%) 53.29%, #fff 97.82%);
			}
		}
	}
}

.filter {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 10px;
	margin-bottom: 40px;
	color: black;

	strong {
		@include xs-only {
			font-size: 14px;
		}
	}

	@include xs-only {
		flex-direction: column;
		align-items: flex-start;
		justify-content: stretch;
		row-gap: 15px;

		.input.input,
		.select_wrapper.select_wrapper {
			width: 100%;
		}
	}

	.input {
		background-color: transparent;
		border: none;
		border-bottom: 1px solid #fff;
		padding: 8px 0;
		color: black;
		font-size: 16px;

		&:focus {
			outline: none;
			padding-bottom: 6px;
			border-bottom-width: 3px;
		}
	}

	.selects {
		display: flex;
		column-gap: 10px;

		@include xs-only {
			align-items: flex-start;
			width: 100%;
		}
	}

	.select_wrapper {
		border: none;
		min-width: 152px;
		background-color: rgba(0, 0, 0, 0.12);
		border-radius: 50px;

		> span {
			color: black;
			text-align: center;
			padding: 12px 40px 12px 24px;
			width: 100%;

			@include xs-only {
				font-size: 16px;
			}

			&:global(.placeholder) {
				color: rgba(0 0 0 / 70%);
				padding: 12px 40px 12px 24px;
				width: 100%;

				&::after {
					right: 20px;
				}
			}

			&::after {
				right: 20px;
			}
		}

		:global(.select_menu) {
			background-color: white;
			border-radius: 10px;
			color: #000;
			border: 1px solid #ddd;
			padding-top: 0;
			margin-top: 10px;

			div {
				padding: 10px;
				border-radius: 10px;
			}
		}
	}
}

.see_more {
	margin-top: 40px;
}
