@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts_legacy';

.hero_img_background {
	display: flex;
	justify-content: center;
	text-align: center;
	height: 100%;

	picture {
		filter: brightness(0.8);
	}
}

.simpleHeroTextContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	justify-content: space-between;

	@include xs-only {
		.spacer {
			display: none;
		}
	}
}

.new_badge {
	margin-bottom: 10px !important;
	@include sm_up {
		margin-bottom: 20px !important;
	}
}

.title {
	@include max('364px') {
		font-size: 24px;
	}
}

.chevron {
	img {
		width: 32px;
		height: 32px;
		margin-top: 10px;
		cursor: pointer;
	}
}

.button_link {
	position: unset !important;
	bottom: unset !important;
	left: unset !important;
	right: unset !important;
	margin: unset !important;

	margin-top: 18px;
	display: none;

	@include md-up {
		margin-top: 24px;
		display: block !important;
	}
}
